import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	withRouter,
	Switch,
} from "react-router-dom";
import page from "./pages";

const MainContainer = withRouter(({ location, history, isOut, isLogined }) => {
	return (
			<Switch>
				{page().map((item) => {
					return (
						<Route
							key={item.path}
							exact={item.exact}
							path={item.path}
							component={item.component}></Route>
					);
				})}
				<Redirect to="/not-found" />
			</Switch>
	);
});

class RouterWrapper extends Component {
	render() {
		return (
			<>
				<Router>
					<MainContainer></MainContainer>
				</Router>
			</>
		);
	}
}

export default RouterWrapper;
