import Agreement from "../Agreement/Agreement";
// import NotFound from "../NotFound/NotFound";

const routes = [
	{
		path: "/",
		component: Agreement,
		exact: true,
	},
	// {
	// 	path: "/login",
	// 	component: Login,
	// 	exact: true,
	// },
	// {
	// 	path: "/not-found",
	// 	component: NotFound,
	// 	exact: true,
	// },
	// {
	// 	path: "/:solution",
	// 	component: SolutionInformation,
	// 	exact: true,
	// },
];

function page(role) {
	return routes.filter((r) => {
		return !r.role || r.role === role;
	});
}
export default page;
