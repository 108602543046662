import RouterWrapper from "./Router/RouterWrapper";
import "./css/common.css";
import "./css/font.css";
import "./css/style.css";

require("dotenv").config();

function App() {
	return <RouterWrapper />;
}

export default App;
