import React from "react";
import logo from "../img/logo.png";
import { withRouter } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import queryString from "query-string";
import cookie from "universal-cookie";
import CryptoJS from 'crypto-js';

class Agreement extends React.Component {
	state = {
		agreement: [],
		agreementId: [],
		agreementStatus: "",
		step: 1,
		userPhone: "",
		ssnInput: "",
	};

	// token;

	componentDidMount = async () => {
		const query = queryString.parse(this.props.location.search);
		if (!query.did) {
			this.getTerms();
		} else {
			try {
				const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/nice/did/${query.did}`);
				if(response.data.code < 0){
					alert("잘못된 요청입니다.");
					this.props.history.push("/");
					window.location.reload(false);
				}else{
					var authData = response.data.data;

					const cookies = new cookie();
					const agreement = cookies.get("agreement");
					const agreementId = cookies.get("agreementId");

					this.setState({
						did: authData.did,
						authBy: authData.authBy,
						agreementStatus: agreement,
						agreementId,
						userName: authData.name,
						userPhone: authData.mobile
							? authData.mobile
									.replace(/[^0-9]/g, "")
									.replace(
										/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
										"$1-$2-$3"
									)
									.replace("--", "-")
							: "",
						userResidentId: authData.birthdate.substring(2, authData.birthdate.length),
						step: 2,
					});
				}
			} catch (e) {
				console.log(e);
				alert("잘못된 요청입니다.");
				this.props.history.push("/");
				window.location.reload(false);
			}
		}
	};

	checkIdentity1() {
		let wl = window.location;

		//인증 후 callback URL
		const returnUrl = `${process.env.REACT_APP_BASE_URL}/nice/decrypt/data`;

		//callback 후 WAS에서 최종적으로 redirect 시킬 URL(결과 화면)
		const redirectUrl = `https://${wl.host}`;

		axios({
			url: `${process.env.REACT_APP_BASE_URL}/nice/encrypt/data`,
			method: "GET",
			params: { returnUrl, redirectUrl },
			withCredentials: true,
		})
			.then((res) => {
				console.log("res : ", res);
				//encode data;
				let encodeData = res.data;
				document.form_chk.action =
					"https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
				document.form_chk.EncodeData.value = encodeData;
				//submit! (본인인증 화면으로 전환)
				document.form_chk.submit();
			})
			.catch((err) => {
				console.error(err);
			});
	}

	checkIdentity2() {
		let wl = window.location;

		//인증 후 callback URL
		const returnUrl = `${process.env.REACT_APP_BASE_URL}/nice/ipin/decrypt/data`;

		//callback 후 WAS에서 최종적으로 redirect 시킬 URL(결과 화면)
		const redirectUrl = `https://${wl.host}`;

		axios({
			url: `${process.env.REACT_APP_BASE_URL}/nice/ipin/encrypt/data`,
			method: "GET",
			params: { returnUrl, redirectUrl },
			withCredentials: true,
		})
			.then((res) => {
				console.log("res : ", res);
				//encode data;
				let encodeData = res.data;
				document.form_chk.action = "https://cert.vno.co.kr/ipin.cb";
				document.form_chk.m.value = "pubmain";
				document.form_chk.enc_data.value = encodeData;
				//submit! (본인인증 화면으로 전환)
				document.form_chk.submit();
			})
			.catch((err) => {
				console.error(err);
			});
	}

	handleConfirm1 = () => {
		let agreementStatus = "";
		let agreementId = [];
		let isAgree = true;
		for (let i = 0; i < this.state.agreement.length; i++) {
			if (this.state.agreement[i].isChecked) {
				if (i < this.state.agreement.length - 1) {
					agreementStatus += "Y/";
				} else {
					agreementStatus += "Y";
				}
				agreementId.push(this.state.agreement[i].id);
			} else {
				if (this.state.agreement[i].necessary) {
					isAgree = false;
				} else {
					if (i < this.state.agreement.length - 1) {
						agreementStatus += "N/";
					} else {
						agreementStatus += "N";
					}
				}
			}
		}
		if (!isAgree) {
			alert("필수 조건에 동의해주세요.");
			return;
		}

		const Cookie = new cookie();
		Cookie.set("agreement", agreementStatus);
		Cookie.set("agreementId", JSON.stringify(agreementId));

		this.checkIdentity1();
	};

	handleConfirm2 = () => {
		let agreementStatus = "";
		let agreementId = [];
		let isAgree = true;
		for (let i = 0; i < this.state.agreement.length; i++) {
			if (this.state.agreement[i].isChecked) {
				if (i < this.state.agreement.length - 1) {
					agreementStatus += "Y/";
				} else {
					agreementStatus += "Y";
				}
				agreementId.push(this.state.agreement[i].id);
			} else {
				if (this.state.agreement[i].necessary) {
					isAgree = false;
				} else {
					if (i < this.state.agreement.length - 1) {
						agreementStatus += "N/";
					} else {
						agreementStatus += "N";
					}
				}
			}
		}
		if (!isAgree) {
			alert("필수 조건에 동의해주세요.");
			return;
		}

		const Cookie = new cookie();
		Cookie.set("agreement", agreementStatus);
		Cookie.set("agreementId", JSON.stringify(agreementId));

		this.checkIdentity2();
	};

	getTerms = async () => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/api/main/ddb_api_terms`
			);
			const data = response.data.data;
			const agreement = data
				.filter((item) => item.ISHIDDEN === "N")
				.map((item) => {
					const termData = {
						id: item.TERMID,
						title: item.TITLE,
						content: item.html,
						necessary: item.OPTIONAL === "Y" ? false : true,
						isChecked: undefined,
					};
					return termData;
				});
			this.setState({ agreement });
		} catch (e) {
			console.log(e);
			alert("서버 오류입니다.");
		}
	};

	onCheck = (id) => {
		this.setState({
			agreement: this.state.agreement.map((item) => {
				if (item.id === id) {
					item.isChecked = !item.isChecked;
				}
				return item;
			}),
		});
	};

	onDischeck = (id) => {
		this.setState({
			agreement: this.state.agreement.map((item) => {
				if (item.id === id) {
					item.isChecked = false;
				}
				return item;
			}),
		});
	};

	isAllChecked = () => {
		if (this.state.agreement.find((item) => !item.isChecked)) {
			return false;
		} else {
			return true;
		}
	};

	allCheck = () => {
		if (this.state.agreement.find((item) => !item.isChecked)) {
			this.setState({
				agreement: this.state.agreement.map((item) => {
					item.isChecked = true;
					return item;
				}),
			});
		} else {
			this.setState({
				agreement: this.state.agreement.map((item) => {
					item.isChecked = undefined;
					return item;
				}),
			});
		}
		this.forceUpdate();
	};

	getPurifyDOM = (html) => {
		return DOMPurify.sanitize(html, {
			FORBID_TAGS: ["head", "body"],
		});
	};

	handleRequest = async () => {
		if (!this.state.ssnInput || this.state.ssnInput.length < 7) {
			alert("주민등록번호 뒷자리를 입력해주세요.");
		} else {
			try {
				const body = {
					did: this.state.did,
					userSSN: this.encodeByAES56(this.state.ssnInput),
					confirmedTerm: this.state.agreementId,
					confirmStatus: this.state.agreementStatus
				};

				// const isValidPhone = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/.test(
				// 	body.userNum
				// );
				// if (!isValidPhone) {
				// 	alert("올바른 휴대폰번호를 입력해 주세요.");
				// 	return;
				// }

				const namecheckRes = await axios.get(
					encodeURI(
						`${process.env.REACT_APP_BASE_URL}/nice/did/${body.did}/namecheck?user_id=${encodeURIComponent(body.userSSN)}`
					)
				);

				if (namecheckRes.data.code !== 1) {
					alert("올바른 주민등록번호를 입력해 주세요.");
					return;
				}

				await axios.post(
					`${process.env.REACT_APP_BASE_URL}/api/main/ddb_api_create-user`,
					body
				);
				this.props.history.push("/");
				this.setState({ step: 3 });
			} catch (e) {
				console.log(e);
				alert("서버 오류입니다.");
			}
		}
	};

	handleOnLoadHtml = (e, src) => {
		const iframeDocument = e.target.contentDocument;
		const content = src;
		iframeDocument.open("text/html", "replace");
		iframeDocument.write(content);
		iframeDocument.close();
	};

	handleOnLoadHtml = (e, src) => {
		const iframeDocument = e.target.contentDocument;
		const content = src;
		iframeDocument.open("text/html", "replace");
		iframeDocument.write(content);
		iframeDocument.close();
	};

	encodeByAES56(data){
		const k = process.env.REACT_APP_AES_KEY;

		const rk = k.padEnd(32, " "); // AES256은 key 길이가 32자여야 함

		const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(rk), {
			iv: CryptoJS.enc.Utf8.parse(""),
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		});
		return cipher.toString();
	}

	renderStep = () => {
		if (this.state.step === 1) {
			return (
				<div className="agreement__contents">
					<form name="form_chk" method="post">
						<input type="hidden" name="m" value="checkplusService" />
						<input type="hidden" name="EncodeData" value="" />
						<input type="hidden" name="enc_data" value="" />
					</form>
					<div className="head_logo">
						<img src={logo} alt="더블저축은행" className="logo" />
					</div>
					<div name="fregister" id="fregister">
						<div className="agreement__contents_body">
							<h2 className="h2_title notoB f_color_w">이용약관 동의</h2>
							<div id="" className="chk_all chk_all_box">
								<label
									htmlFor="chk_all"
									className="chk_container chk_text"
									style={{ marginLeft: "35px" }}>
									아래 모든 약관에 동의 합니다.
									<input
										type="checkbox"
										name="chk_all"
										id="chk_all"
										className="selec_chk"
										defaultValue={this.isAllChecked()}
										checked={this.isAllChecked()}
										onChange={this.allCheck}
									/>
									<span
										className="checkmark"
										style={{ top: "10px", left: "15px" }}></span>
								</label>
							</div>
							{this.state.agreement.map((item, index) => {
								return (
									<div id="" className="fregister_agree" key={index}>
										<p className="text notoB f_color_blue">
											{item.title}
											<span
												className={
													item.necessary
														? "f_color_orange notoR"
														: "f_color_blue1 notoR"
												}>
												{item.necessary ? "(필수)" : "(선택)"}
											</span>
										</p>
										<fieldset
											className="fieldset_box"
											style={{ height: "33px" }}>
											<label htmlFor={index} className="chk_container">
												<input
													type="checkbox"
													name="agree"
													checked={item.isChecked}
													defaultValue={item.isChecked}
													id={index}
													onChange={() => this.onCheck(item.id)}
													className=""
												/>
												<span
													className="checkmark"
													style={{
														position: "relative",
														display: "inline-block",
														top: "7px",
														left: "0px",
													}}></span>
												<span style={{ marginLeft: "7px" }}>동의함</span>
											</label>
											<label
												htmlFor={index + "disable"}
												className="chk_container">
												<input
													type="checkbox"
													name="disagree"
													checked={
														item.isChecked !== undefined &&
														item.isChecked === false
													}
													defaultValue={
														item.isChecked !== undefined &&
														item.isChecked === false
													}
													id={index + "disable"}
													onChange={() => this.onDischeck(item.id)}
													className=""
												/>
												<span
													className="checkmark"
													style={{
														position: "relative",
														display: "inline-block",
														top: "7px",
														left: "10px",
													}}></span>
												<span style={{ marginLeft: "20px" }}>
													동의하지 않음
												</span>
											</label>
										</fieldset>
										<iframe
											title={item.TITLE}
											name={index}
											id={index}
											style={{
												width: "100%",
												height: "100px",
												pageBreakAfter: "always",
											}}
											src={item.content}></iframe>
										{/* <div
											className="div_textarea_agree"
											dangerouslySetInnerHTML={{
												__html: this.getPurifyDOM(item.content),
											}}></div> */}
									</div>
								);
							})}
						</div>
						<div class="n_ft">
							<div class="tx1">
							상호: (주)더블저축은행<span>│</span>대표자: 이승재<br />
							사업자등록번호: 408-81-07152<span>│</span>T. 062-223-5506<br />
							[61480] 광주광역시 동구 중앙로 165
							</div>
							<div class="tx2">Copyright(C) ALL Right Reserved.</div>
						</div>
						<div className="agreement__contents_foot_fixed">
							<ul>
								<li style={{ width: "33.333333%" }}>
									<button className="btn_n2 bg_blue1 notoB f_color_w">
										취소
									</button>
								</li>
								<li style={{ width: "33.333333%" }}>
									<button
										className="btn_n1 bg_orange notoB f_color_w"
										onClick={this.handleConfirm1}>
										휴대폰 인증
									</button>
								</li>
								<li style={{ width: "33.333333%" }}>
									<button
										className="btn_n1 bg_green notoB f_color_w"
										onClick={this.handleConfirm2}>
										아이핀 인증
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		}
		if (this.state.step === 2) {
			return (
				<div className="agreement__contents">
					<div className="head_logo">
						<img src={logo} alt="더블저축은행" className="logo" />
					</div>
					<div name="fregister" id="fregister">
						<div className="agreement__contents_body">
							<h2 className="h2_title notoB f_color_w">대출신청</h2>

							<div className="fregister_agree">
								<div className="input_box">
									{/* <!-- 인증 후 자동 입력 * 수정할 수 없음 * --> */}
									<p className="tx1 f_color_blue notoB">이름</p>
									<input
										type="text"
										name="name"
										className="input_style_01"
										value={this.state.userName || ""}
										readOnly
									/>
								</div>
								<div className="input_box">
									<p className="tx1 f_color_blue notoB">휴대전화</p>
									<input
										type="text"
										name="hp"
										className="input_style_01"
										value={this.state.userPhone}
										// onInput={(e) => {
										// 	if (e.target.value.length === 11 || e.target.value.length === 13 ) {
										// 		this.setState(prevState => ({
										// 		  phone: e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")
										// 		}))
										// 	}else{
										// 		console.log({ userPhone: e.target.value })
										// 		this.setState({ userPhone: e.target.value });
										// 	}
										// }}
										// onInput={(e) =>
										// 	e.target.value.length === 11 ||
										// 	e.target.value.length === 13
										// 		? (e.target.value = e.target.value.replace(
										// 				/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
										// 				"$1-$2-$3"
										// 		  ))
										// 		: e.target.value
										// }
										// onChange={(e) => {
										// 	this.setState({ userPhone: e.target.value });
										// }}
										readOnly={this.state.authBy === "phone"}
									/>
								</div>
								<div className="input_box">
									<p className="tx1 f_color_blue notoB">주민등록번호</p>
									<ul className="resident_number">
										{/* <!-- 주민번호 앞 자리는 자동 입력 --> */}
										<li>
											<input
												type="text"
												name="number1"
												className="input_style_01"
												value={this.state.userResidentId || ""}
												readOnly
											/>
										</li>
										<li>-</li>
										<li>
											<input
												type="password"
												name="number1"
												className="input_style_02 bg_orange"
												required
												maxLength="7"
												onInput={(e) =>
													(e.target.value = e.target.value
														.replace(/[^0-9.]/g, "")
														.replace(/(\..*)\./g, "$1"))
												}
												onChange={(e) => {
													this.setState({ ssnInput: e.target.value });
												}}
												placeholder="뒷자리를 입력해주세요!"
												autoComplete="false"
											/>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="agreement__contents_foot_fixed">
							<ul>
								<li className="li_100">
									<button
										onClick={this.handleRequest}
										type="button"
										className="btn_n1 bg_orange notoB f_color_w">
										신청하기
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		}
		if (this.state.step === 3) {
			return (
				<div className="agreement__contents">
					<div className="head_logo">
						<img src={logo} alt="더블저축은행" className="logo" />
					</div>
					<div name="fregister" id="fregister">
						<div className="agreement__contents_body">
							<div className="fregister_agree2">
								<div className="end_text1">
									<span className="f_color_blue notoB">
										{this.state.userName}
									</span>
									님의
									<br />
									접수가 정상적으로{" "}
									<span className="f_color_orange notoB">완료</span>
									되었습니다.
								</div>
							</div>
							<div className="end_text2 f_color_w bg_orange">
								접수 내용은 상담원을 통해 별도로 연락드리겠습니다.
								<br />
								이용해주셔서 감사합니다.
							</div>
						</div>
						<div className="agreement__contents_foot_fixed">
							<ul>
								<li className="li_100">
									<button
										type="submit"
										onClick={this.toFirst}
										className="btn_n1 bg_blue1 notoB f_color_w">
										처음으로
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			);
		}
	};

	toFirst = () => {
		this.props.history.push("/");
		window.location.reload(false);
	};

	render() {
		return this.renderStep();
	}
}

export default withRouter(Agreement);
